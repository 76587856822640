
const swiper = new Swiper('.swiper-about', {
	direction: 'horizontal',
	loop: true,

	navigation: {
		nextEl: '.swiper-about .swiper-button-next',
		prevEl: '.swiper-about .swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
	},
});
const swiper4 = new Swiper('.swiper-product', {
	direction: 'horizontal',
	loop: true,

	navigation: {
		nextEl: '.swiper-product .swiper-button-next',
		prevEl: '.swiper-product .swiper-button-prev',
	},
	
});
const swiper3 = new Swiper('.swiper-opinion', {
	direction: 'horizontal',
	loop: true,
	navigation: {
		nextEl: '.swiper-opinion .swiper-button-next',
		prevEl: '.swiper-opinion .swiper-button-prev',
	},
});
if ($(window).width() < 768) {
	const swiper2 = new Swiper('.swiper-offer', {

		direction: 'horizontal',
		loop: true,
		pagination: {
			el: '.swiper-offer .swiper-pagination',
			clickable: "true",
		},
	});
}